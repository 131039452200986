import { DownloadIcon } from "vue-feather-icons";
import axiosInstance from "./Api";

export default {

  async getSingleProductDetails(payload) {
    let response = await axiosInstance().get(`/product/single/${payload}`);
    return response;
  },

  async downloadFile(name) {
    let response = await axiosInstance().get(`/product/downloadImage/${name}`);
    return response;
  },

  async getSellerItems(uid, type) {
    let response = await axiosInstance().get(`/product/all/${uid}/${type}`);
    return response;
  },

  async getAdminSellerItems(uid, type) {
    let response = await axiosInstance().get(`/product/admin-all/${uid}/${type}`);
    return response;
  },

  async getSoldProducts() {
    let response = await axiosInstance().get(`/product/getSoldProducts/`);
    return response;
  },

  async getItemLogs() {
    let response = await axiosInstance().get(`/product/getLogs/items/all/`);
    return response;
  },

  async SaveProduct(payload) {
    let response = await axiosInstance().post("/product/save", payload);
    return response;
  },

  async getAllItems() {
    let response = await axiosInstance().get(`/product/`);
    return response;
  },

  async getAdminAllItems() {
    let response = await axiosInstance().get(`/product/admin-all-active-items`);
    return response;
  },


  // WORDPRESS APIs - START
  async getAdminWPProducts(page, currency) {
    const current_page = page? page : 0;
    const current_currency = currency ? currency : "INR";
    let response = await axiosInstance().get(`/wp/product-paginate/${current_page}/${current_currency}`);
    return response;
  },

  async getAdminWPAllMasterAttributes() {
    let response = await axiosInstance().get(`/wp/prod/attributes`);
    return response;
  },

  async getAdminWPAttributeDetails(attr_id) {
    let response = await axiosInstance().get(`/wp/prod/attributes/${attr_id}`);
    return response;
  },

  async getAdminWPAllCategories() {
    let response = await axiosInstance().get(`/wp/prod/categories`);
    return response;
  },

  async getAdminWPAllBrands() {
    let response = await axiosInstance().get(`/wp/prod/brands`);
    return response;
  },

  async getAdminWPProductDetails(pid) {
    let response = await axiosInstance().get(`/wp/product/${pid}`);
    return response;
  },

  async saveAdminWPProductUpdate(data) {
    let response = await axiosInstance().post(`/wp/product/update`, data);
    return response;
  },

  async saveAdminWPProductCreate(data) {
    let response = await axiosInstance().post(`/wp/product/create`, data);
    return response;
  },

  async saveAdminWPProductVariationCreate(data) {
    let response = await axiosInstance().post(`/wp/create-variation`, data);
    return response;
  },

  async saveAdminWPUploadImageToS3(data) {
    let response = await axiosInstance().post(`/wp/product/up-images`, data);
    return response;
  },

  async saveAdminWPUploadImageToS3Multi(data) {
    let response = await axiosInstance().post(`/wp/product/up-images-multi`, data);
    return response;
  },

  // WORDPRESS APIs - END

  async getSellItems() {
    let response = await axiosInstance().get(`/product/by/sell`);
    return response;
  },

  async getRefurbishItems() {
    let response = await axiosInstance().get(`/product/by/refurbish`);
    return response;
  },

  async updateStatus(payload) {
    let response = await axiosInstance().post("/product/updateStatus", payload);
    return response;
  },

  async updateShippingStatus(payload) {
    let response = await axiosInstance().post("/product/updateShippingStatus", payload);
    return response;
  },

  async addProductComment(payload) {
    let response = await axiosInstance().post("/product/addProductComment", payload);
    return response;
  },

  async getComments(uid){
    let response = await axiosInstance().get(`/product/status/getComments/${uid}`);
    return response;
  },

  async updateRefurbishStatus(payload) {
    let response = await axiosInstance().post("/product/updateRefurbishStatus", payload);
    return response;
  },

  async updateRefurbishComment(payload) {
    let response = await axiosInstance().post("/product/updateRefurbishComment", payload);
    return response;
  },

  async getRefurbishComment(payload) {
    let response = await axiosInstance().post("/product/getRefurbishComment", payload);
    return response;
  },

  async syncProductsWithWoocom(payload) {
    let response = await axiosInstance().get(`/product/syncProductsWithWoocom`);
    return response;
  },

  async updateSellComment(payload) {
    let response = await axiosInstance().post("/product/updateSellComment", payload);
    return response;
  },

  async getSellComment(payload) {
    let response = await axiosInstance().post("/product/getSellComment", payload);
    return response;
  },


  async listAllSellItemComments(payload) {
    let response = await axiosInstance().post("/product/listAllSellItemComments", payload);
    return response;
  },

  async updateActiveInactive(payload) {
    let response = await axiosInstance().post("/product/updateActiveInactive", payload);
    return response;
  },

  async shareItemDetails(payload) {
    let response = await axiosInstance().post("/product/shareItemDetails", payload);
    return response;
  },

  async updateRefDateTime(payload) {
    let response = await axiosInstance().post("/product/updateRefDateTime", payload);
    return response;
  },

  async updateSellDateTime(payload) {
    let response = await axiosInstance().post("/product/updateSellDateTime", payload);
    return response;
  },

  async downloadPDF(payload) {
    let response = await axiosInstance().post("/product/downloadPDF", payload);
    return response;
  },

  async updateSellAddress(payload) {
    let response = await axiosInstance().post("/product/updateSellAddress", payload);
    return response;
  },

  async updatePickupLabel(payload) {
    let response = await axiosInstance().post("/product/updatePickupLabel", payload);
    return response;
  },

  async saveUpdateUnderRevivify(payload) {
    let response = await axiosInstance().post("/product/saveUpdateUnderRevivify", payload);
    return response;
  },

  async sendMessage(payload) {
    let response = await axiosInstance().post("/product/sendMessage", payload);
    return response;
  },

  async getItemMessages(id) {
    let response = await axiosInstance().get(`/product/get-item-chats/${id}`);
    return response;
  },

  async addAttrValues(payload) {
    let response = await axiosInstance().post(`/wp/prod/attributes/value`, payload);
    return response;
  },

};
