<template>
  <div>
    <!-- ALL ITEMS CARD -->
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6" offset-md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                class="d-inline-block mr-1"
                placeholder="Search By Item ID, Type, Name"
                v-model="keyword"
                type="text"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- ALl Item table -->
      <b-table
        id="NewProductListTableId"
        ref="refNewProductListTable"
        :items="allItems"
        responsive
        :fields="tableColumns"
        primary-key="prod_id"
        :per-page="pagination.perPage"
        :current-page="pagination.currentPage"
        :keyword="keyword"
      >
        <!-- Column: Id -->
        <template #cell(index)="data">
          <span class="text-black">
            {{
              parseInt((pagination.currentPage - 1) * pagination.perPage) +
              data.index +
              1
            }}
          </span>
        </template>

        <template #cell(id)="data" class="text-black">
          <b-link
            :to="{
              name: `product-details`,
              query: { id: data.item.prod_id },
            }"
            class="font-weight-bold"
            @click="
              $router.push({
                name: `product-details`,
                query: { id: data.item.prod_id },
              })
            "
          >
            {{ data.item.prod_auto_id }}
          </b-link>
        </template>

        <!-- Column: Client -->
        <template #cell(item_type)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              <!-- {{ data.item.prod_type.tag_name }} -->
              {{ data.item.prod_sub_category }}
            </span>
          </b-media>
        </template>

        <!-- Column: Client -->
        <template #cell(client)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.prod_designer }}
            </span>
          </b-media>
        </template>

        <template #cell(item_prod_type)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ titleCase(data.item.prod_item_type) }}
            </span>
          </b-media>
        </template>

        <!-- category -->
        <template #cell(status)="data">
          <div class="d-flex align-items-center">
            <span v-if="data.item.prod_item_type == 'sell'">
              {{
                newStatus(
                  data.item.prod_published,
                  data.item.prod_status_save_type,
                  data.item.prod_status,
                  "sell"
                )
              }}
            </span>

            <span v-if="data.item.prod_item_type == 'refurbish'">
              {{
                newStatus(
                  data.item.prod_published,
                  data.item.prod_status_save_type,
                  data.item.prod_status,
                  "refurbish"
                )
              }}
            </span>
          </div>
        </template>

        <!-- Column: Issued Date -->
        <template #cell(issuedDate)="data">
          <span class="text-nowrap">
            {{ data.item.prod_prod_date }}
          </span>
        </template>

        <!-- Column: Balance -->
        <template #cell(amount)="data">
          <div>
            {{ data.item.prod_amount ? "£ " + data.item.prod_amount : "N/A" }}
          </div>
        </template>

        <!-- Column: Balance -->
        <template #cell(date_of_submition)="data">
          <div>
            {{ getHumanDate(data.item.prod_created_at) }}
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(pending_actions)="data">
          <div class="text-nowrap">
            <!-- <feather-icon icon="EyeIcon" size="16" class="mx-1" :key="data" /> -->

            <span :key="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-if="data.item.prod_published"
                  @click="deactivate(data.item.prod_id, 0)"
                >
                  <feather-icon icon="XCircleIcon" class="mr-50" />
                  <span>Deactivate</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="!data.item.prod_published"
                  @click="deactivate(data.item.prod_id, 1)"
                >
                  <feather-icon icon="CheckCircleIcon" class="mr-50" />
                  <span>Activate</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </div>
        </template>
      </b-table>

      <b-pagination
        class="pl-2"
        aria-controls="NewProductListTableId"
        v-model="pagination.currentPage"
        :total-rows="allItems.length"
        :per-page="pagination.perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>

        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BCardText,
  BModal,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import feather from "feather-icons";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import storageService from "@/apiServices/storageService";
import ProductServices from "@/apiServices/ProductServices";

import { refurbishStatus } from "./refurbishStatusCode";
import { sellStatus } from "./sellStatusCode";

import { avatarText } from "@core/utils/filter";
import moment from "moment";
import axios from "axios";

export default {
  components: {
    BCardText,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BModal,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormSelect,
    BFormSelectOption,
    feather,
    FeatherIcon,
    moment,
  },
  setup() {},
  beforeMount() {
    this.loadAllItems();
  },
  directives: {
    Ripple,
  },
  computed: {
    allItems() {
      return this.keyword
        ? this.allItemsData.filter((item) => {
            if (item.prod_designer || item.prod_auto_id) {
              if (
                item.prod_designer
                  .toString()
                  .toLowerCase()
                  .includes(this.keyword.toString().toLowerCase()) ||
                item.prod_auto_id
                  .toString()
                  .includes(this.keyword.toString()) ||
                item.prod_sub_category
                  .toString()
                  .toLowerCase()
                  .includes(this.keyword.toString().toLowerCase())
              ) {
                console.log("Searching => ", item);
                return true;
              } else {
                console.log("Saerch False =>", item);
                return false;
              }
            }
          })
        : this.allItemsData;
    },
  },
  data() {
    return {
      sellStatus: sellStatus,
      refurbishStatus: refurbishStatus,
      sellStatusObj: {},
      refurbishStatusObj: {},

      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      allItemsData: [],
      keyword: "",
      pagination: {
        currentPage: 1,
        perPage: 5,
        perPageOptions: [10, 20, 30],
      },

      tableColumns: [
        "index",
        { key: "id", label: "Item ID", sortable: true },
        { key: "item_type", label: "Item Type", sortable: true },
        { key: "client", label: "Item Name", sortable: true },
        { key: "item_prod_type", label: "Service Type", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "date_of_submition", label: "Created Date", sortable: true },
        { key: "pending_actions", label: "Actions" },
      ],
    };
  },
  methods: {
    loadAllItems() {
      const profileData = storageService.UserData.getUserProfile();
      let userInfo = JSON.parse(profileData);
      let user_id = userInfo.user_id;

      ProductServices.getSellerItems(user_id, "all")
        .then((response) => {
          this.allItemsData = response.data.data;
          console.log("allItemsData: ", this.allItemsData);
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    showSellStatusMessage(code) {
      let obj = {};
      const x = this.sellStatus.filter((o, index) => {
        if (o.status_code === code) obj = this.sellStatus[index];
      });
      return obj;
    },

    showRefurbishStatusMessage(code) {
      let obj = {};
      const x = this.refurbishStatus.filter((o, index) => {
        if (o.status_code === code) obj = this.refurbishStatus[index];
      });
      return obj;
    },

    titleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    deactivate(prod_id, type) {
      console.log(prod_id);

      const data = {
        prod_id: prod_id,
        type: type,
      };

      ProductServices.updateActiveInactive(data)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "BoxIcon",
              variant: "success",
            },
          });

          this.loadAllItems();
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    newStatus(active, draft, status, type) {
      if (active === true) {
        if (draft == "save") {
          if (type == "sell") {
            let obj = {};
            const x = this.sellStatus.filter((o, index) => {
              if (o.status_code === parseInt(status))
                obj = this.sellStatus[index];
            });
            return obj.status_message;
          } else if (type == "refurbish") {
            let obj = {};
            const x = this.refurbishStatus.filter((o, index) => {
              if (o.status_code === parseInt(status))
                obj = this.refurbishStatus[index];
            });
            return obj.status_message;
          } else {
            return "Under Review";
          }
        } else {
          return "Saved As Draft";
        }
      } else {
        return "Deactivated";
      }
    },
  },
};
</script>

<style>
.table {
  color: #444 !important;
}

[dir] .table th,
[dir] .table td {
  padding: 1.5rem;
}

[dir] .card {
  border-radius: 1rem !important;
}

/* [dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th, [dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th{
  background-color: white !important;
} */

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th {
  background-color: #ffffff00 !important;
}

[dir] .table thead th {
  border-bottom: 0px !important;
  /* text-shadow : 2px 1px 1px 2px #000000; */
}

th > div {
  /* text-shadow : 2px 1px 1px 2px #000000 !important;  */
  color: black !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.swal2-html-container {
  color: white;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
