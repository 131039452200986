<template>
  <div>
    <div class="mb-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mr-1"
        :to="{ name: 'sell_refurbish' }"
      >
        Add Item
      </b-button>

      <b-button-group>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          v-bind:class="[itemType == 'all' ? 'active' : '']"
          v-on:click="changeTableType('all')"
        >
          All Items
        </b-button>
        <!-- <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    v-bind:class="[itemType == 'sell' ? 'active' : '']"
                    v-on:click="changeTableType('sell')"
                >
                Sell Items
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    v-bind:class="[itemType == 'refurbish' ? 'active' : '']"
                    v-on:click="changeTableType('refurbish')"
                >
                Refurbish Item
                </b-button> -->
      </b-button-group>
    </div>

    <seller-all-item-list v-if="itemType == 'all'" />
    <b-row>
      <b-col class="text-center">
        <a href="/" >Back to Home</a>
      </b-col>
    </b-row>
    <!-- <sell-item-list v-if="itemType == 'sell'" />
    <refurbish-item-list v-if="itemType == 'refurbish'" /> -->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BCardText,
  BButtonGroup,
} from "bootstrap-vue";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import SellerAllItemList from "./SellerAllItemList.vue";
import SellItemList from "./SellItemList.vue";
import RefurbishItemList from "./RefurbishItemList.vue";

export default {
   metaInfo: {
    title: 'Item List | Revivify',
    htmlAttrs: {
      lang: 'en-US'
    },
  },
  components: {
    BCardText,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BButtonGroup,
    BPagination,
    SellerAllItemList,
    SellItemList,
    RefurbishItemList,
  },
  directives: {
    Ripple,
  },
  setup() {},
  data() {
    return {
      itemType: "all",
    };
  },
  computed: {},
  methods: {
    changeTableType(itemType) {
      this.itemType = itemType;
    },
  },
};
</script>

<style lang="scss" scoped>
.uppercase {
  text-transform: uppercase !important;
}

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
</style>
