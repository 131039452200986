<template>
  <div>
    <!-- ALL ITEMS CARD -->
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="6" offset-md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                class="d-inline-block mr-1"
                placeholder="Search..."
                v-model="keyword"
                type="text"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- ALl Item table -->
      <b-table
        ref="refNewProductListTable"
        :items="sellItems"
        responsive
        :fields="sellTableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
        <!-- Auto ID [Sr.No] -->
        <template #cell(index)="data">
          {{
            parseInt((pagination.currentPage - 1) * pagination.perPage) +
            data.index +
            1
          }}
        </template>

        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link
            :to="{
              name: `product-details`,
              query: { id: data.item.prod_id },
            }"
            class="font-weight-bold"
            @click="
              $router.push({
                name: `product-details`,
                query: { id: data.item.prod_id },
              })
            "
          >
            {{ data.item.prod_auto_id }}
          </b-link>
        </template>

        <!-- Column: Item -->
        <template #cell(item)="data">
          <span class="text-nowrap">
            {{ data.item.prod_type.item_type_name }}
          </span>
        </template>

        <!-- Column: Designer -->
        <template #cell(designer)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.prod_designer }}
            </span>
          </b-media>
        </template>

        <!-- STATUS -->
        <template #cell(status)="data">
          <div class="d-flex align-items-center">
            {{
              newStatus(
                data.item.prod_published,
                data.item.prod_status_save_type,
                data.item.prod_status,
                "sell"
              )
            }}
          </div>
        </template>

        <template #cell(date_submitted)="data">
          <div :key="data">
            {{ getHumanDate(data.item.prod_created_at) }}
          </div>
        </template>

        <!-- EARNINGS -->
        <template #cell(earnings)="data">
          <span class="text-nowrap">
            {{ data.item.prod_amount_currency + " 1234" }}
          </span>
        </template>

        <!-- LISTIN PRICE -->
        <template #cell(listing_price)="data">
          <span class="text-nowrap">
            {{ data.item.prod_amount_currency + " " + data.item.prod_amount }}
          </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon icon="EyeIcon" size="16" class="mx-1" :key="data" />
          </div>
        </template>
      </b-table>

      <b-pagination
        aria-controls="NewProductListTableId"
        v-model="pagination.currentPage"
        :total-rows="sellItemData.length"
        :per-page="pagination.perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>

        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BCardText,
  BModal,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import feather from "feather-icons";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import storageService from "@/apiServices/storageService";
import ProductServices from "@/apiServices/ProductServices";

import { sellStatus } from "./sellStatusCode";

import { avatarText } from "@core/utils/filter";
import moment from "moment";
import axios from "axios";

export default {
  components: {
    BCardText,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BModal,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormSelect,
    BFormSelectOption,
    feather,
    FeatherIcon,
    moment,
  },
  setup() {},
  beforeMount() {
    this.loadAllItems();
  },
  directives: {
    Ripple,
  },
  computed: {
    sellItems() {
      return this.keyword
        ? this.sellItemData.filter((x) =>
            x.prod_designer.includes(this.keyword)
          )
        : this.sellItemData;
    },
  },
  data() {
    return {
      sellStatus: sellStatus,
      sellStatusObj: {},

      sellItemData: [],
      keyword: "",
      pagination: {
        currentPage: 1,
        perPage: 5,
        perPageOptions: [10, 20, 30],
      },
      sellTableColumns: [
        "index",
        { key: "id", label: "ID", sortable: true },
        { key: "item", label: "Item name", sortable: true },
        { key: "designer", label: "Designer", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "date_submitted", label: "Submission Date", sortable: true },
        { key: "earnings", label: "Earnings", sortable: true },
        { key: "listing_price", label: "Listing Price", sortable: true },
        { key: "actions", label: "Pending Actions" },
      ],
    };
  },
  methods: {
    loadAllItems() {
      const profileData = storageService.UserData.getUserProfile();
      let userInfo = JSON.parse(profileData);
      let user_role_id = userInfo.user_role_id;

      ProductServices.getSellerItems(user_role_id, "sell")
        .then((response) => {
          this.sellItemData = response.data.data;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    newStatus(active, draft, status, type) {
      if (active === true) {
        if (draft == "save") {
          if (type == "sell") {
            let obj = {};
            const x = this.sellStatus.filter((o, index) => {
              if (o.status_code === parseInt(status))
                obj = this.sellStatus[index];
            });
            return obj.status_message;
          } else if (type == "refurbish") {
            let obj = {};
            const x = this.refurbishStatus.filter((o, index) => {
              if (o.status_code === parseInt(status))
                obj = this.refurbishStatus[index];
            });
            return obj.status_message;
          } else {
            return "Under Review";
          }
        } else {
          return "Saved As Draft";
        }
      } else {
        return "Deactivated";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.swal2-html-container {
  color: white;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
